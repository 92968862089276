import './bootstrap';

import '@egjs/vue3-flicking/dist/flicking.css'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import { tns } from 'tiny-slider'
import Cookies from 'js-cookie'

document.Cookies = Cookies

let SwipeDetect = (function () {
	function SwipeDetect(element) {
		this.xDown  =  null;
		this.yDown  =  null;
		this.element  =  typeof (element) ===  'string'  ?  document.querySelector(element) :  element;
		this.element.addEventListener('touchstart', function (evt) {
			this.xDown  =  evt.touches[0].pageX;
			this.yDown  =  evt.touches[0].pageY;
		}.bind(this), false);
	}

	SwipeDetect.prototype.onLeft  =  function (callback) {
		this.onLeft  =  callback;
		return this;
	};
	SwipeDetect.prototype.onRight  =  function (callback) {
		this.onRight  =  callback;
		return this;
	};
	SwipeDetect.prototype.onUp  =  function (callback) {
		this.onUp  =  callback;
		return this;
	};
	SwipeDetect.prototype.onDown  =  function (callback) {
		this.onDown  =  callback;
		return this;
	};

	SwipeDetect.prototype.handleTouchMove  =  function (evt) {
		if (!this.xDown  ||  !this.yDown) {
			return;
		}
		let  xUp  =  evt.changedTouches[0].pageX;
        let  yUp  =  evt.changedTouches[0].pageY;
		this.xDiff  = this.xDown  -  xUp;
		this.yDiff  = this.yDown  -  yUp;

		if (Math.abs(this.xDiff) !==  0) {
			if (this.xDiff  >  150) {
				typeof (this.onLeft) ===  "function"  && this.onLeft();
			} else  if (this.xDiff  <  -150) {
				typeof (this.onRight) ===  "function"  && this.onRight();
			}
		}

		if (Math.abs(this.yDiff) !==  0) {
			if (this.yDiff  >  150) {
				typeof (this.onUp) ===  "function"  && this.onUp();
			} else  if (this.yDiff  <  -150) {
				typeof (this.onDown) ===  "function"  && this.onDown();
			}
		}
		// Reset values.
		this.xDown  =  null;
		this.yDown  =  null;
	};

	SwipeDetect.prototype.run  =  function () {
		this.element.addEventListener('touchend', function (evt) {
			this.handleTouchMove(evt);
		}.bind(this), false);
	};

	return  SwipeDetect;
}());

document.addEventListener("DOMContentLoaded", function(event) {
    if (document.getElementsByClassName('gallery-slider').length){
        let slider = tns({
            container: '.gallery-slider',
            items: 1,
            center: true,
            loop: true,
            edgePadding: 30,
            nav: false,
            controlsText: ['<i class="far fa-angle-double-left gallery-slider-prev"></i>', '<i class="far fa-angle-double-right gallery-slider-next"></i>'],
            responsive: {
                992: {
                    items: 2,
                    edgePadding: -200
                }
            }
        });

        function updateActiveSlide(info){
            for (let i = 0; i < info.slideItems.length; i++){
                if (info.slideItems[i] !== undefined) {
                    if (i === info.index) {
                        info.slideItems[i].classList.add('active-slide')
                    } else {
                        info.slideItems[i].classList.remove('active-slide')
                    }
                }
            }
        }

        slider.events.on('indexChanged', updateActiveSlide)

        updateActiveSlide(slider.getInfo())
    }

    let bannerPlaceholders = document.getElementsByClassName('banner-wrapper')
    let requestPayload = {
        banners: [],
        galleries: [],
        url: location.href
    }
    for (let i = 0; i < bannerPlaceholders.length; i++){
        bannerPlaceholders[i].id = 'bannerWrapper_' + (i + 1)
        let item = {
            type: bannerPlaceholders[i].dataset.type,
            index: i + 1
        }
        if (bannerPlaceholders[i].dataset.bannerId !== undefined && bannerPlaceholders[i].dataset.bannerId !== null){
            item.bannerId = bannerPlaceholders[i].dataset.bannerId
        }
        requestPayload.banners.push(item)
    }
    let galleries = document.getElementsByClassName('images-section')
    for (let i = 0; i < galleries.length; i++){
        let item = {
            imageCount: galleries[i].dataset.count,
            id: galleries[i].dataset.id
        }
        requestPayload.galleries.push(item)
    }

    let posts = document.getElementsByClassName('post-details')
    if (posts.length){
        requestPayload.postId = posts[0].dataset.id;
    }

    if (requestPayload.banners.length) {
        axios.post('/banners', requestPayload)
            .then(function (response) {
                _.forEach(response.data.banners, function(markup, index){
                    let target = document.getElementById('bannerWrapper_' + index);
                    if (target.dataset.type === 'popup'){
                        if (Cookies.get('popupDisplayed')){
                            target.remove()
                            return
                        } else {
                            Cookies.set('popupDisplayed', 1)
                        }
                    }
                    target.insertAdjacentHTML("beforeend", markup);
                    let scripts = target.getElementsByTagName("script");
                    while (scripts.length) {
                        let script = scripts[0];
                        script.parentNode.removeChild(script);
                        let newScript = document.createElement("script");
                        if (script.src) {
                            newScript.src = script.src;
                        } else if (script.textContent) {
                            newScript.textContent = script.textContent;
                        } else if (script.innerText) {
                            newScript.innerText = script.innerText;
                        }
                        document.body.appendChild(newScript);
                    }
                })
                _.forEach(response.data.galleries, function(banners, id){
                    let target = document.createElement('div');
                    target.setAttribute('id', 'galleryBanners_' + id)
                    target.dataset.banners = JSON.stringify(banners)
                    document.body.appendChild(target);
                })
            })
            .catch(function (error) {
            });
    }
});

import Vue, { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
import Gallery from "./frontend/Gallery.vue";
import RecommendedPosts from "./frontend/RecommendedPosts.vue";
import Poll from "./frontend/Poll.vue";
import ReadingProgress from "./frontend/ReadingProgress.vue";
import Game from "./frontend/Game.vue";
import Countdown from "./frontend/Countdown.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import PostSearchField from "./frontend/PostSearchField.vue";
import VueCookies from 'vue3-cookies'
import ThemeSwitch from "./frontend/ThemeSwitch.vue";
import SupportUs from "./frontend/SupportUs.vue";
import PostSlider from "./frontend/PostSlider.vue";
import Vue3TouchEvents from "vue3-touch-events";
import MobilePostSearch from "./frontend/MobilePostSearch.vue";


const app = createApp()
import ScreenSize from 'screen-size-vue'
import RelatedPosts from "./frontend/RelatedPosts.vue";

setTimeout(function(){
	let postContent = document.querySelector('section.post-content-body')
	if (postContent){
		let swiper = new SwipeDetect(postContent);
		swiper.onLeft(function (){
			location.href = postContent.dataset.nextUrl
		})
		swiper.onRight(function (){
			location.href = postContent.dataset.prevUrl
		})
		swiper.run()
	}
}, 100)

app.use(ScreenSize)
app.use(Vue3TouchEvents)
app.use(VueLazyLoad)
app.use(VueCookies, {
	path: "/",
	secure: false,
});
app.component('Poll', Poll)
app.component('Gallery', Gallery)
app.component('Countdown', Countdown)
app.component('VueCountdown', VueCountdown)
app.component('RecommendedPosts', RecommendedPosts)
app.component('ReadingProgress', ReadingProgress)
app.component('Game', Game)
app.component('PostSearchField', PostSearchField)
app.component('ThemeSwitch', ThemeSwitch)
app.component('SupportUs', SupportUs)
app.component('PostSlider', PostSlider)
app.component('MobilePostSearch', MobilePostSearch)
app.component('RelatedPosts', RelatedPosts)
app.mount('#vue')
