<template>
	<div class="related-posts" v-if="$data.$screenSize.breakPoint === 'm' || $data.$screenSize.breakPoint === 'l' || $data.$screenSize.breakPoint === 'xl'">
		<h5>Kapcsolódó cikkek</h5>
		<div class="row">
			<div class="col-lg-4 col-sm-6 col-xs-12" v-for="post in posts">
				<article>
					<div class="img">
						<img :src="post.thumbnail" class="img-fluid" :alt="post.title">
					</div>
					<h2>{{ post.title }}</h2>
					<a :href="post.url"></a>
				</article>
			</div>
		</div>
	</div>
	<div v-else class="related-posts-slider">
		<h5>Kapcsolódó cikkek</h5>
		<carousel :items-to-show="1" :wrap-around="true" ref="slider" aria-label="Kapcsolódó cikkek" @init="sliderInited = true">
			<slide v-for="(post, postIndex) in posts" :key="'slide_' + postIndex">
				<article>
					<div class="img">
						<img :src="post.thumbnail" class="img-fluid" :alt="post.title">
					</div>
					<h2>{{ post.title }}</h2>
					<a :href="post.url"></a>
				</article>
			</slide>
		</carousel>

		<template v-if="sliderInited">
			<div class="related-posts-slider-indicator">{{ indicatorText }}</div>
		</template>
	</div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
	components: {Carousel, Slide, Pagination, Navigation},
	props: {
		posts: Array
	},
	mounted() {
	},
	computed: {
		indicatorText: {
			get(){
				if (this.sliderInited !== undefined) {
					let max = this.$refs.slider.data.slidesCount.value
					return Math.min(max, this.$refs.slider.data.currentSlide.value + 1) + ' / ' + max
				}

				return ''
			}
		}
	},
	data(){
		return {
			sliderInited: false
		}
	},
	methods: {
	},
}
</script>

<style scoped>

</style>
